import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Mixpanel } from "../../../utility/Mixpanel"
import TagsItem from "./TagsItem"

export default function TagsList({ tags, scannerSetter }) {
  const handleClick = () => {
    scannerSetter(true)
    Mixpanel.track("Open Tag Scanner")
  }

  const markup = tags.map((tag, index) => (
    <TagsItem tag={tag} key={index} />
  ))

  return (
    <>
      <div className="tags-list">{markup}</div>
      <div className="button" onClick={() => handleClick()}>
        <FontAwesomeIcon className="button__icon" icon={["fas", "qrcode"]} />
        <span>Activate a tag</span>
      </div>
    </>
  )
}