import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import TagManager from "react-gtm-module"
import { FirebaseProvider } from "./contexts/FirebaseContext"
import AuthRoute from "./utility/AuthRoute"
import UnauthRoute from "./utility/UnauthRoute"
import ErrorList from "./components/errors/ErrorList"
import SignInPage from "./pages/auth/SignInPage"
import SignUpPage from "./pages/auth/SignUpPage"
import ResetPasswordPage from "./pages/auth/ResetPasswordPage"
import Library from "./pages/library/Library"
import Media from "./pages/media/Media"
import Tags from "./pages/tags/Tags"
import "./assets/stylesheets/global.scss"

const tagManagerArgs = {
  gtmId: "GTM-KR29WFK"
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <>
      <BrowserRouter>
        <FirebaseProvider>
          <ErrorList />
          <Routes>
            <Route path="/signin" element={<UnauthRoute><SignInPage /></UnauthRoute>} />
            <Route path="/signup" element={<UnauthRoute><SignUpPage /></UnauthRoute>} />
            <Route path="/reset" element={<UnauthRoute><ResetPasswordPage /></UnauthRoute>} />
            <Route path="/library" element={<AuthRoute><Library /></AuthRoute>} />
            <Route path="/library/:id" element={<AuthRoute><Media /></AuthRoute>} />
            <Route path="/library/:id/:index" element={<AuthRoute><Media /></AuthRoute>} />
            <Route path="/tags" element={<AuthRoute><Tags /></AuthRoute>} />
            <Route path="/" element={<AuthRoute><Navigate to="/library" /></AuthRoute>} />
          </Routes>
        </FirebaseProvider>
      </BrowserRouter>
    </>
  )
}

ReactDOM.createRoot(document.getElementById("root")).render(<App />)