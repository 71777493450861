import mixpanel from "mixpanel-browser"
mixpanel.init("4c4bb4b232da31e2188a511106bbffae")

let actions = {
  identify: (id) => mixpanel.identify(id),
  alias: (id) => mixpanel.alias(id),
  track: (name, props) => mixpanel.track(name, props),
  people: {
    set: (props) => mixpanel.people.set(props)
  }
}

export let Mixpanel = actions