import { useMemo } from "react"
import { CSSTransitionGroup } from "react-transition-group"
import { useFirebase } from "../../contexts/FirebaseContext"
import ErrorItem from "./ErrorItem"
import "./errors.scss"

export default function ErrorList() {
  const { errors } = useFirebase()
  const markup = useMemo(() => {
    if (!errors?.length) { return <></> }
    else {
      return errors.map((error, index) => (
        <ErrorItem error={error} key={index} />
      ))
    }
  }, [errors])

  return (
    <div className="errors-list">
      <CSSTransitionGroup
        transitionName="error"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {markup}
      </CSSTransitionGroup>
    </div>
  )
}