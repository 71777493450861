import { useRef } from "react"
import { useNavigate, Link } from "react-router-dom"
import { useFirebase } from "../../contexts/FirebaseContext"
import logo from "../../assets/images/logo.svg"
import "./auth.scss"

export default function AuthPage() {
  const { signIn } = useFirebase()
  const emailRef = useRef()
  const passwordRef = useRef()
  const navigateTo = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      await signIn(emailRef.current.value, passwordRef.current.value)
      navigateTo("/")
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-page__card">
        <div className="auth-page__card__inner">
          <img src={logo} className="auth-page__logo" alt="Hightag" />
          <form className="form" onSubmit={handleSubmit}>
            <input className="form__textfield" type="email" ref={emailRef} placeholder="Email address" required />
            <input className="form__textfield" type="password" ref={passwordRef} placeholder="Password" required />
            <button className="button" type="submit">Sign in</button>
            <span className="form-switch__phrase reset-password">
              <Link className="form-switch__link" to="/reset">Forgot your password?</Link>
            </span>
          </form>
        </div>
      </div>
      <div className="auth-page__card">
        <div className="auth-page__card__inner">
          <span className="form-switch__phrase">Don't have an account?&nbsp;
            <Link className="form-switch__link" to="/signup">Sign up</Link>
          </span>
        </div>
      </div>
    </div>
  )
}