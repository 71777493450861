import React, { useMemo } from "react"

export default function MediaDisplay({ mediaItem, index }) {
  const imagePath = mediaItem?.path?.[index] || ""
  
  const imageElem = useMemo(() => {
    return <img className="mediaDisplay__image" src={imagePath} alt="Captured media" />
  }, [imagePath])

  return (
    <div className="mediaDisplay">
      {imageElem}
    </div>
  )
}