import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Mixpanel } from "../../../utility/Mixpanel"

export default function TagsEmpty({ scannerSetter }) {
  const handleClick = () => {
    scannerSetter(true)
    Mixpanel.track("Open Tag Scanner")
  }

  return (
    <>
      <div className="tags-empty">
        <div className="tags-empty__stage">
          <div className="tags-empty__icon__wrapper">
            <FontAwesomeIcon className="tags-empty__icon" icon={["fas", "sensor"]} />
          </div>
          <span className="tags-empty__message">No active tags</span>
        </div>
      </div>
      <div className="button" onClick={() => handleClick()}>
        <FontAwesomeIcon className="button__icon" icon={["fas", "qrcode"]} />
        <span>Activate a tag</span>
      </div>
    </>
  )
}