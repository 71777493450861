import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFirebase } from "../../contexts/FirebaseContext"
import Nav from "../../components/nav/Nav"
import Main from "../../components/layout/Main"
import Container from "../../components/layout/Container"
import Masthead from "../../components/layout/Masthead"
import Toolbar from "../../components/layout/Toolbar"
import LibraryList from "./components/LibraryList"
import LibraryEmpty from "./components/LibraryEmpty"
import "./library.scss"

export default function LibraryPage() {
  const { tags, richMedia } = useFirebase()
  let markup, promobar

  tags.length
    ? promobar = <></>
    : promobar = (
      <Link className="promobar" to="/tags">
        <FontAwesomeIcon className="promobar__icon" icon={["fas", "circle-info"]} />
        You don't have any active tags. Click here to activate one.
      </Link>
    )

  richMedia.length
    ? markup = <LibraryList richMedia={richMedia} />
    : markup = <LibraryEmpty />

  return (
    <>
      <Nav />
      <Main>
        <Masthead addClass="library-page__masthead"><h1>Media Library</h1></Masthead>
        <Toolbar />
        <Container>
          {promobar}
          {markup}
        </Container>
      </Main>
    </>
  )
}