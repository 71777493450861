import { useState } from 'react'
// import { NavLink, Link, useNavigate } from 'react-router-dom'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
// import { useFirebase } from '../../contexts/FirebaseContext'
import Masthead from '../layout/Masthead'
import logo from '../../assets/images/logo.svg'
import './nav.scss'

export default function Nav({ backTo }) {
  // const { signOut } = useFirebase()
  const [menuOpen, setMenuOpen] = useState(false)
  // const navigate = useNavigate()
  let menuStyles = null
  // let backAction
  
  // OLD HANDLE SIGN OUT FUNCTION
  // async function handleSignOut() {
  //   try {
  //     await signOut()
  //     navigate('/signin')
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  menuOpen
    ? menuStyles = { transform: 'translateX(0%)' }
    : menuStyles = null

  function toggleMenuOpen() {
    setMenuOpen(prevState => !prevState)
  }

  // OLD CODE FOR BACK LINKING
  // backTo
  //   ? backAction = (
  //     <Link className='nav__action' to={backTo}>
  //       <FontAwesomeIcon className='nav__action__icon' icon='chevron-left' />
  //     </Link>
  //   )
  //   : backAction = <div className='nav__action'></div>
  
  return (
    <aside className='nav'>

      <Masthead>
        {/* <div className='nav__actions'>
          {backAction}
          <Link className='nav__brand__link' to='/library'>
            <img src={logo} className='nav__brand__logo' alt='Hightag logo' />
          </Link>
          <div className='nav__action' onClick={() => toggleMenuOpen()}>
            <FontAwesomeIcon className='nav__action__icon' icon={icon({name: 'bars', family: 'sharp', style: 'solid'})} />
          </div>
        </div> */}


        <Link className='nav__brand__link' to='/library'>
          <img src={logo} className='nav__brand__logo' alt='Hightag logo' />
        </Link>
      </Masthead>
      
      <nav className='menu' style={menuStyles}>

        {/*
        OLD MENU CLOSE BUTTON FOR MOBILE
        <div className='menu__close'>
          <div className='nav__action' onClick={() => toggleMenuOpen()}>
            <span className='nav__action__label'>Close</span>
            <FontAwesomeIcon className='nav__action__icon' icon='xmark' />
          </div>
        </div>
        */}
        <NavLink to='/' className={(navData) => navData.isActive ? 'menu__item active' : 'menu__item'}>
          <FontAwesomeIcon className='menu__item__icon' icon={icon({name: 'house', family: 'sharp', style: 'solid'})} />
          <span className='menu__item__label'>Home</span>
        </NavLink>
        <NavLink to='/library' className={(navData) => navData.isActive ? 'menu__item active' : 'menu__item'}>
          <FontAwesomeIcon className='menu__item__icon' icon={icon({name: 'grid-2', family: 'sharp', style: 'solid'})} />
          <span className='menu__item__label'>Library</span>
        </NavLink>
        <NavLink to='/tags' className={(navData) => navData.isActive ? 'menu__item active' : 'menu__item'}>
          <FontAwesomeIcon className='menu__item__icon' icon={icon({name: 'sensor', family: 'sharp', style: 'solid'})} />
          <span className='menu__item__label'>Tags</span>
        </NavLink>
        <a href='https://www.hightag.app/#locations' className='menu__item' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon className='menu__item__icon' icon={icon({name: 'location-dot', family: 'sharp', style: 'solid'})} />
          <span className='menu__item__label'>Locations</span>
        </a>

        {/*
        OLD SIGN OUT LINK AT BOTTOM OF SIDEBAR
        <div className='menu__section'>
          <NavLink to='/signin' className={(navData) => navData.isActive ? 'menu__item active' : 'menu__item'} onClick={handleSignOut}>
            <FontAwesomeIcon className='menu__item__icon' icon={icon({name: 'right-from-bracket', family: 'sharp', style: 'solid'})} />
            <span className='menu__item__label'>Sign out</span>
          </NavLink>
        </div>
        */}

      </nav>

    </aside>
  )
}