import { useMemo } from "react"
import dayjs from "dayjs"
import LibraryItem from "./LibraryItem"

export default function LibraryGroup({ group = {} }) {
  const groupDate = group.date
  let groupItems = group.media
  const locationName = groupItems[0].locationName
  const locationCity = groupItems[0].locationCity
  const locationState = groupItems[0].locationState
  
  const markup = useMemo(() => {
    if (!groupItems?.length) {
      return null
    } else {
      return (
        groupItems.sort((a, b) => {
            return b.datetime - a.datetime
          }).map((libraryItem, index) => (
            <LibraryItem libraryItem={libraryItem} key={index} />
          ))
      )
    }
  }, [groupItems])
  
  return (
    <div className="library-group">
      <div className="library-group__header">
        <h3 className="library-group__date">
          {dayjs(groupDate).format("MMM D")}
        </h3>
        <div className="library-group__place">
          <span className="library-group__place__name">{locationName}</span>
          <i className="library-group__place__divider" />
          <span>{locationCity}, {locationState}</span>
        </div>
      </div>
      <div className="library-group__gallery">{markup}</div>
    </div>
  )
}