import React, { useMemo } from "react"
import { NavLink, useParams } from "react-router-dom"

export default function MediaThumbnails({ mediaItem }) {
  const imageRefs = mediaItem?.path || []
  const { id } = useParams()
  
  const thumbnails = useMemo(() => {
    if (!imageRefs.length) {
      return null
    } else {
      return imageRefs.map((path, index) => {
        const thumbnailRef = mediaItem?.path_thumbnails?.[index] || path
        const reducedPath = thumbnailRef.replace("https://storage.googleapis.com/rfid-pytest.appspot.com","https://ik.imagekit.io/hightag/tr:w-144,h-144,q-50")
        return (
          <NavLink
            className={(navData) => navData.isActive ? "media-thumbnails__item active" : "media-thumbnails__item"}
            key={index}
            to={{ pathname: `/library/${id}/${index}`, media: mediaItem }}
            style={{ backgroundImage: `url(${reducedPath})` }}
            alt="Captured media"
          />
        )
      })
    }
  // eslint-disable-next-line
  }, [id, mediaItem])

  return (
    <div className="media-thumbnails">
      {thumbnails}
    </div>
  )
}