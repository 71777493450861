import { useState, useCallback } from "react"
import { useFirebase } from "../../contexts/FirebaseContext"
import Nav from "../../components/nav/Nav"
import Main from "../../components/layout/Main"
import Toolbar from "../../components/layout/Toolbar"
import Container from "../../components/layout/Container"
import Masthead from "../../components/layout/Masthead"
import TagsList from "./components/TagsList"
import TagsScanner from "./components/TagsScanner"
import TagsInput from "./components/TagsInput"
import TagsEmpty from "./components/TagsEmpty"
import "./tags.scss"

export default function TagsPage() {
  const { tags } = useFirebase()
  const [scanning, setScanning] = useState(false)
  const [inputting, setInputting] = useState(false)
  const scannerSetter = useCallback(value => { setScanning(value) }, [setScanning])
  const inputSetter = useCallback(value => { setInputting(value) }, [setInputting])
  let markup

  switch (true) {
    case tags.length && !scanning && !inputting:
      markup = <TagsList tags={tags} scannerSetter={scannerSetter} />
      break
    case !tags.length && !scanning && !inputting:
      markup = <TagsEmpty scannerSetter={scannerSetter} />
      break
    case scanning:
      markup = <TagsScanner scannerSetter={scannerSetter} inputSetter={inputSetter} />
      break
    case inputting:
      markup = <TagsInput inputSetter={inputSetter} />
      break
    default:
      markup = <TagsEmpty scannerSetter={scannerSetter} />
  }
  
  return (
    <>
      <Nav />
      <Main>
        <Masthead addClass="tags-page__masthead"><h1>My Tags</h1></Masthead>
        <Toolbar></Toolbar>
        <Container>
          <div className="tags-page">{markup}</div>
        </Container>
      </Main>
    </>
  )
}