import { useRef } from "react"
import { Link } from "react-router-dom"
import { useFirebase } from "../../contexts/FirebaseContext"
import logo from "../../assets/images/logo.svg"
import "./auth.scss"

export default function AuthPage() {
  const emailRef = useRef()
  const { resetPassword } = useFirebase()

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      await resetPassword(emailRef.current.value)
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-page__card">
        <div className="auth-page__card__inner">
          <img src={logo} className="auth-page__logo" alt="Hightag" />
          <form className="form" onSubmit={handleSubmit}>
            <input className="form__textfield" type="email" ref={emailRef} placeholder="Email address" required />
            <button className="button" type="submit">Reset password</button>
          </form>
        </div>
      </div>
      <div className="auth-page__card">
        <div className="auth-page__card__inner">
          <span className="form-switch__phrase">Remembered your password?&nbsp;
            <Link className="form-switch__link" to="/signin">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  )
}