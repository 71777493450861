import { useParams } from "react-router-dom"
import { useFirebase } from "../../contexts/FirebaseContext"
import Nav from "../../components/nav/Nav"
import Main from "../../components/layout/Main"
import Container from "../../components/layout/Container"
import MediaSidebar from "./components/MediaSidebar"
import MediaDisplay from "./components/MediaDisplay"
import MediaThumbnails from "./components/MediaThumbnails"
import "./media.scss"

export default function MediaPage() {
  const { id, index } = useParams()
  const { richMedia } = useFirebase()
  const mediaItem = richMedia.find(m => m.id === id)
  
  return (
    <>
      <Nav backTo="/library" backLabel="Library" />
      <Main>
        <Container>
          <div className="media-page">
            <MediaDisplay mediaItem={mediaItem} index={index} />
            <MediaThumbnails mediaItem={mediaItem} />
          </div>
          <MediaSidebar mediaItem={mediaItem} index={index} />
        </Container>
      </Main>
    </>
  )
}