import { useRef } from "react"
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore"
import { db } from "../../../config/firebase"
import { useFirebase } from "../../../contexts/FirebaseContext"
import { Mixpanel } from "../../../utility/Mixpanel"

export default function TagsInput({ inputSetter }) {
  const { currentUser } = useFirebase()
  const aliasRef = useRef()

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      const tagsColRef = collection(db, "tags")
      const tagsDocQry = query(tagsColRef, where("tag_alias", "==", aliasRef.current.value))
      const tagsQrySnp = await getDocs(tagsDocQry)
      
      tagsQrySnp.forEach(item => {
        const tagDocRef = doc(db, "tags", item.id)
        updateDoc(tagDocRef, { user_id: currentUser.uid })
      })
      inputSetter(false)
    } catch(error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    inputSetter(false)
    Mixpanel.track("Close Tag Input")
  }

  return (
    <div className="tags-input">
      <div className="tags-input__stage">
        <form className="form" onSubmit={handleSubmit}>
          <span className="tags-input__instruction">Enter the 4-digit ID on your tag following the letters "HT".</span>
          <input className="form__textfield" type="text" ref={aliasRef} placeholder="Example: 0000" required />
          <button className="button" type="submit">Activate tag</button>
        </form>
      </div>
      <div className="button alt" onClick={() => handleCancel()}>
        <span>Cancel</span>
      </div>
    </div>
  )
}