import { Navigate, useLocation } from "react-router-dom"
import { useFirebase } from "../contexts/FirebaseContext"

export default function AuthRoute({ children }) {
  const { currentUser } = useFirebase()
  const location = useLocation()

  if (!currentUser) {
    return <Navigate to="/signin" state={{ from: location }} />
  }

  return children
}