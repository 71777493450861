import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function LibraryEmpty() {
  return (
    <div className="library-empty">
      <div className="library-empty__stage">
        <div className="library-empty__icon__wrapper">
          <FontAwesomeIcon className="library-empty__icon" icon={["fas", "camera"]} />
        </div>
        <span className="library-empty__message">No media yet</span>
      </div>
    </div>
  )
}