import React, { useContext, useState, useEffect, useCallback } from "react"
import { doc, setDoc, serverTimestamp, collection, onSnapshot, query, where } from "firebase/firestore"
import { Mixpanel } from "../utility/Mixpanel"
import { auth, db } from "../config/firebase"

const FirebaseContext = React.createContext()

export function useFirebase() {
  return useContext(FirebaseContext)
}

export function FirebaseProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const [locations, setLocations] = useState([])
  const [tags, setTags] = useState([])
  const [userProfile, setUserProfile] = useState({})
  const [rawMedia, setRawMedia] = useState([])
  const [richMedia, setRichMedia] = useState([])
  const [errors, setErrors] = useState([])
  const timestamp = serverTimestamp()
  const locationsRef = collection(db, "locations")
  const decode = {
    "auth/user-not-found": "There's no account with that email address.",
    "auth/too-many-requests": "Too many sign in attempts. Please try again in a few minutes.",
    "auth/user-disabled": "This account has been disabled.",
    "auth/user-token-expired": "Your session has expired. Please sign in again.",
    "auth/id-token-expired": "Your session has expired. Please sign in again.",
    "auth/email-already-exists": "There is already an account with that email address.",
    "auth/insufficient-permission": "Your account doesn't have permission to do that.",
    "auth/internal-error": "Something went wrong on our end. Please try again.",
    "auth/invalid-argument": "Looks like you entered something incorrectly. Please try again.",
    "auth/invalid-email": "You've entered an invalid email.",
    "auth/invalid-id-token": "Your session access token is invalid. Please sign in again.",
    "auth/invalid-password": "The password you've entered is invalid. Passwords must be at least 6 characters.",
    "auth/invalid-phone-number": "You've entered an invalid phone number.",
    "auth/phone-number-already-exists": "There is already an account with that phone number.",
    "auth/session-cookie-expired": "Your session cookie has expired. Please sign in again.",
    "auth/session-cookie-revoked": "Your session cookie has been revoked. Please sign in again.",
    "auth/uid-already-exists": "A user with that ID already exists.",
    "auth/wrong-password": "The password you've entered is incorrect. Please try again or reset your password.",
  }

  const addError = useCallback(error => {
    setErrors(prevState => [...prevState, error])
  }, [setErrors])
  
  const clearError = useCallback(id => {
    setErrors(prevState => prevState.filter((error) => {
      return error.id !== id
    }))
  }, [setErrors])

  let profileRef = null
  let tagsRef = null
  let mediaRef = null
  
  if (currentUser) {
    profileRef = doc(db, "users", currentUser.uid)
    tagsRef = query(collection(db, "tags"), where("user_id", "==", currentUser.uid))
    mediaRef = query(collection(db, "media"), where("user_id", "==", currentUser.uid))
  }



  // ==========================================================================================
  // Set locations from Firestore snapshot listener
  // ==========================================================================================

  useEffect(() => {
    onSnapshot(locationsRef, snapshot => {
      console.log("Fetching locations")
      let locationsArr = []

      snapshot.forEach(doc => {
        const id = doc.id
        const data = doc.data()
        const locationsObj = { id, ...data }
        
        locationsArr.push(locationsObj)
      })

      setLocations(locationsArr)
    }, error => {
      console.log(error)
      const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
      setErrors(prevState => [...prevState, {
        id: Math.random().toString(36).substring(2, 15),
        message: errorStr
      }])
    })
  // eslint-disable-next-line
  }, [])



  // ==========================================================================================
  // Set currentUser from Firebase auth change
  // ==========================================================================================

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      user
        ? console.log("Setting current user")
        : console.log("No current user")
    }, error => {
      console.log(error)
      const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
      setErrors(prevState => [...prevState, {
        id: Math.random().toString(36).substring(2, 15),
        message: errorStr
      }])
    })
    // eslint-disable-next-line
  }, [])



  // ==========================================================================================
  // Set user's profile from Firestore snapshot listener
  // ==========================================================================================

  useEffect(() => {
    if (currentUser) {
      onSnapshot(profileRef, snapshot => {
        console.log("Fetching user's profile")
        setUserProfile(snapshot.data())
      }, error => {
        console.log(error)
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
    } else {
      setUserProfile(null)
      console.log("No user profile")
    }
  // eslint-disable-next-line
  }, [currentUser])



  // ==========================================================================================
  // Set user's tags from Firestore snapshot listener
  // ==========================================================================================

  useEffect(() => {
    if (currentUser) {
      onSnapshot(tagsRef, snapshot => {
        console.log("Fetching user's tags")
        let tagsArr = []

        snapshot.forEach(doc => {
          const id = doc.id
          const data = doc.data()
          const tagsObj = { id, ...data }

          tagsArr.push(tagsObj)
        })

        setTags(tagsArr)
      }, error => {
        console.log(error)
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
    } else {
      setTags([])
      console.log("No user tags")
    }
  // eslint-disable-next-line
  }, [currentUser])



  // ==========================================================================================
  // Set user's media from Firestore snapshot listener
  // ==========================================================================================

  useEffect(() => {
    if (currentUser) {
      onSnapshot(mediaRef, snapshot => {
        console.log("Fetching user's media")
        let mediaArr = []

        snapshot.forEach(doc => {
          const id = doc.id
          const data = doc.data()
          const datetime = new Date(data.createdAt.seconds * 1000)
          const date = datetime.toDateString()
          const mediaObj = {
            id,
            datetime,
            date,
            ...data
          }

          if (data?.path?.length && !data?.hidden) { mediaArr.push(mediaObj) }
        })

        setRawMedia(mediaArr)
      }, error => {
        console.log(error)
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
    } else {
      setRawMedia([])
      setRichMedia([])
      console.log("No user media")
    }
  // eslint-disable-next-line
  }, [currentUser])



  // ==========================================================================================
  // Enrich rawMedia with locations data
  // ==========================================================================================

  useEffect(() => {
    if (locations?.length && rawMedia?.length) {
      console.log("Enriching user media")
      let mediaArr = []

      rawMedia.forEach(item => {
        const location = locations.find(l => l.id === item.location_id)
        const mediaObj = {
          locationName: location.location_name,
          locationCity: location.city,
          locationState: location.state,
          ...item
        }
        
        mediaArr.push(mediaObj)
      })

      setRichMedia(mediaArr)
    }
  }, [locations, rawMedia])



  // ==========================================================================================
  // Auth actions
  // ==========================================================================================

  function signUp(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
      .then(userCredentials => {
        setDoc(doc(db, "users", userCredentials.user.uid), {
          email: email,
          createdAt: timestamp
        })
        Mixpanel.alias(userCredentials.user.uid)
        Mixpanel.track("Successful Sign Up")
        Mixpanel.people.set({
          "$email": email
        })
        setErrors([])
      })
      .catch(error => {
        console.log(error)
        Mixpanel.track("Unsuccessful Sign Up")
        Mixpanel.people.set({
          "$email": email
        })
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
  }
  
  function signIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
      .then(userCredentials => {
        Mixpanel.identify(userCredentials.user.uid)
        Mixpanel.track("Successful Sign In")
        Mixpanel.people.set({
          "$email": email
        })
        setErrors([])
      })
      .catch(error => {
        console.log(error)
        Mixpanel.track("Unsuccessful Sign In")
        Mixpanel.people.set({
          "$email": email
        })
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
  }
  
  function signOut() {
    return auth.signOut()
      .then(() => {
        setErrors([])
      })
      .catch(error => {
        console.log(error)
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
  }
  
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
      .then(() => {
        setErrors([])
      })
      .catch(error => {
        console.log(error)
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
  }
  
  function updatePassword(password) {
    return currentUser.updatePassword(password)
      .then(() => {
        setErrors([])
      })
      .catch(error => {
        console.log(error)
        const errorStr = decode?.[error.code] ? decode[error.code] : "Something went wrong. Please try again."
        setErrors(prevState => [...prevState, {
          id: Math.random().toString(36).substring(2, 15),
          message: errorStr
        }])
      })
  }
  


  const value = {
    locations,
    tags,
    currentUser,
    userProfile,
    rawMedia,
    richMedia,
    errors,
    addError,
    clearError,
    signIn,
    signUp,
    signOut,
    resetPassword,
    updatePassword
  }
  
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}