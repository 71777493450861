import { doc, updateDoc } from "firebase/firestore"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { db } from "../../../config/firebase"

export default function TagsItem({ tag }) {
  const tagID = tag.id
  const tagDocRef = doc(db, "tags", tagID)
  const handleClick = async () => {
    // let result = window.confirm("Are you sure you want to remove this tag from your account?")
    // if (!result) return

    await updateDoc(tagDocRef, {
      user_id: "Null"
    })
  }

  return (
    <div className="tags-item">
      <div className="tags-item__info">
        <FontAwesomeIcon className="tags-item__icon" icon={["fas", "sensor"]} />
        <span>{"•••••••• " + tag.id.substring(tag.id.length - 4)}</span>
      </div>
      <div className="tags-item__action" onClick={() => handleClick()}>
        <span className="tags-item__action__label">Remove</span>
        <FontAwesomeIcon className="tags-item__action__icon" icon={["fas", "xmark"]} />
      </div>
    </div>
  )
}