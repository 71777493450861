import { Link } from "react-router-dom"
import { Mixpanel } from "../../../utility/Mixpanel"

export default function LibraryItem({ libraryItem = {} }) {
  const burstPath = "/library/" + libraryItem.id
  let linkPath
  let thumbnailPath

  const handleClick = () => {
    Mixpanel.track("Open Media View")
  }
  
  if (libraryItem.cover_index && libraryItem.cover_index >= 0 && libraryItem.cover_index < libraryItem.path.length) {
    linkPath = burstPath + "/" + libraryItem.cover_index
    thumbnailPath = libraryItem.path[libraryItem.cover_index]
  } else {
    linkPath = burstPath + "/" + Math.floor(libraryItem.path.length / 2)
    thumbnailPath = libraryItem.path[Math.floor(libraryItem.path.length / 2)]
  }

  const reducedPath = thumbnailPath.replace("https://storage.googleapis.com/rfid-pytest.appspot.com","https://ik.imagekit.io/hightag/tr:w-480,h-480,q-50")
  
  return (
    <Link to={{ pathname: linkPath, media: libraryItem }} className="library-item" onClick={() => handleClick()}>
      <img className="library-item__thumbnail" src={reducedPath} alt="Captured media" />
    </Link>
  )
}