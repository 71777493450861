import { useRef } from "react"
import { useNavigate, Link } from "react-router-dom"
import { useFirebase } from "../../contexts/FirebaseContext"
import logo from "../../assets/images/logo.svg"
import "./auth.scss"

export default function AuthPage() {
  const { signUp } = useFirebase()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const navigateTo = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return console.log("Passwords do not match")
    }
    try {
      await signUp(emailRef.current.value, passwordRef.current.value)
      navigateTo("/")
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-page__card">
        <div className="auth-page__card__inner">
          <img src={logo} className="auth-page__logo" alt="Hightag" />
          <form className="form" onSubmit={handleSubmit}>
            <input className="form__textfield" type="email" ref={emailRef} placeholder="Email address" required />
            <input className="form__textfield" type="password" ref={passwordRef} placeholder="Password" required />
            <input className="form__textfield" type="password" ref={passwordConfirmRef} placeholder="Confirm password" required />
            <button className="button" type="submit">Sign up</button>
          </form>
        </div>
      </div>
      <div className="auth-page__card">
        <div className="auth-page__card__inner">
          <span className="form-switch__phrase">Already have an account?&nbsp;
            <Link className="form-switch__link" to="/signin">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  )
}