import { NavLink, useNavigate, Link } from "react-router-dom"
import { doc, updateDoc } from "firebase/firestore"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { db } from "../../../config/firebase"

export default function MediaSidebar({ mediaItem, index }) {
  const navigateTo = useNavigate()
  let formattedDate = "-"
  let formattedTime = "-"
  const mediaPath = mediaItem.path[index]
  const mediaDocRef = doc(db, "media", mediaItem?.id)
  // console.log(mediaItem)

  const location = {
    name: mediaItem?.locationName,
    city: mediaItem?.locationCity,
    state: mediaItem?.locationState
  }
  
  if (dayjs(mediaItem?.datetime).isValid()) {
    formattedDate = dayjs(mediaItem?.datetime).format("MMMM D")
    formattedTime = dayjs(mediaItem?.datetime).format("h:mm A")
  }

  const handleClick = async () => {
    let result = window.confirm("Are you sure you want to delete this burst of photos? This can't be undone.")
    if (!result) return

    navigateTo("/library")

    await updateDoc(mediaDocRef, {
      hidden: true
    })
  }

  return (
    <div className="media-sidebar">
      <div className="media-sidebar__back__wrapper">
        <NavLink className="media-sidebar__back" to="/library">
          <FontAwesomeIcon className="media-sidebar__back__icon" icon={["fas", "chevron-left"]} fixedWidth />
          <span className="media-sidebar__back__label">Back to library</span>
        </NavLink>
      </div>
      <div className="media-sidebar__group__list">
        <div className="media-sidebar__group">
          <FontAwesomeIcon className="media-sidebar__group__icon" icon={["fas", "location-dot"]} fixedWidth />
          <div className="media-sidebar__group__content">
            <span className="media-sidebar__group__info">{location.name || "-"}</span>
            <span className="media-sidebar__group__info minor">{location.city || "-"}, {location.state || "-"}</span>
          </div>
        </div>
        <div className="media-sidebar__group">
          <FontAwesomeIcon className="media-sidebar__group__icon" icon={["fas", "calendar-day"]} fixedWidth />
          <div className="media-sidebar__group__content">
            <span className="media-sidebar__group__info">{formattedDate}</span>
            <span className="media-sidebar__group__info minor">{formattedTime}</span>
          </div>
        </div>
      </div>
      <div className="media-sidebar__action__list">
        <Link to={mediaPath} className="media-sidebar__action" target="_blank" download>
          <FontAwesomeIcon className="media-sidebar__action__icon" icon={["fas", "arrow-down-to-line"]} fixedWidth />
          <span className="media-sidebar__action__info">Download</span>
        </Link>
        <div className="media-sidebar__action" onClick={() => handleClick()}>
          <FontAwesomeIcon className="media-sidebar__action__icon" icon={["fas", "trash-can"]} fixedWidth />
          <span className="media-sidebar__action__info">Delete all</span>
        </div>
      </div>
    </div>
  )
}