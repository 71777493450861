import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import { getFirestore } from "firebase/firestore"

const app = firebase.initializeApp({
  apiKey: "AIzaSyBE4Yik3QPheE9442EKiQThLAIqrdfDJrQ",
  authDomain: "rfid-pytest.firebaseapp.com",
  databaseURL: "https://rfid-pytest.firebaseio.com",
  projectId: "rfid-pytest",
  storageBucket: "rfid-pytest.appspot.com",
  messagingSenderId: "910045832855",
  appId: "1:910045832855:web:11091ad3e19e92a835abd2",
  measurementId: "G-9QK9JWMV70"
})

export const auth = app.auth()
export const db = getFirestore(app)
export default app