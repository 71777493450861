import { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFirebase } from "../../contexts/FirebaseContext"

export default function ErrorItem({ error }) {
  const { clearError } = useFirebase()

  useEffect(() => {
    setTimeout(() => {
      clearError(error.id)
    }, 5000)
  }, [clearError, error.id])

  return (
    <div className="errors-item">
      <span className="errors-item__message">{error.message}</span>
      <FontAwesomeIcon
        className="errors-item__clear"
        icon ={["fas", "xmark"]}
        mask={["fas", "circle"]}
        transform="shrink-4"
        onClick={() => clearError(error.id)}
      />
    </div>
  )
}