import { useEffect, useState } from "react"
import LibraryGroup from "./LibraryGroup"

export default function LibraryList({ richMedia }) {
  const [groups, setGroups] = useState([])
  
  useEffect(() => {
    function groupMedia() {
      const groupsObj = Object.values(richMedia).reduce((groups, item) => {
          if (!groups[item.date]) groups[item.date] = []
          groups[item.date].push(item)
          return groups
      }, {})

      const groupsArr = Object.keys(groupsObj).map(date => {
        return {
          date,
          media: groupsObj[date]
        }
      })

      const sortedGroups = groupsArr.sort((a, b) => {
        var c = new Date(a.date);
        var d = new Date(b.date);
        return d - c;
      })
      
      setGroups(sortedGroups)
    }
    groupMedia()
  }, [richMedia])
  
  return (
    <div className="library-list">
      {groups.map((group, index) => (
        <LibraryGroup group={group} key={index} />
      ))}
    </div>
  )
}