import "./layout.scss";

export default function Masthead({ children, addClass }) {
  let classes
  
  addClass
    ? classes = `masthead ${addClass}`
    : classes = "masthead"

  return (
    <div className={classes}>
      {children}
    </div>
  );
}