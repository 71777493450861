import { Navigate, useLocation } from "react-router-dom"
import { useFirebase } from "../contexts/FirebaseContext"

export default function UnauthRoute({ children }) {
  const { currentUser } = useFirebase()
  const location = useLocation()

  if (!!currentUser) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return children
}