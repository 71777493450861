import { doc, updateDoc } from "firebase/firestore"
import { QrReader } from "react-qr-reader"
import { db } from "../../../config/firebase"
import { useFirebase } from "../../../contexts/FirebaseContext"
import { Mixpanel } from "../../../utility/Mixpanel"

export default function TagsScanner({ scannerSetter, inputSetter }) {
  const { currentUser } = useFirebase()

  const handleScan = async result => {
    if (result) {
      const tagDocRef = doc(db, "tags", result.text)

      await updateDoc(tagDocRef, { user_id: currentUser.uid })
      Mixpanel.track("Successful Tag Activation")

      scannerSetter(false)
    }
  }

  const handleError = error => {
    console.log(error)
    Mixpanel.track("Unsuccessful Tag Activation")
  }

  const handleCancel = () => {
    scannerSetter(false)
    Mixpanel.track("Close Tag Scanner")
  }

  const handleChange = () => {
    scannerSetter(false)
    inputSetter(true)
  }

  return (
    <>
      <div className="tags-scanner">
        <QrReader
          className="tags-scanner__stage"
          onResult={handleScan}
          onError={handleError}
          constraints={{ facingMode: "environment" }}
        />
      </div>
      <div className="tags-scanner__alt">
        <div className="button alt" onClick={() => handleCancel()}>
          <span>Cancel</span>
        </div>
        <span>Having trouble scanning? <span className="tags-scanner__alt__action" onClick={() => handleChange()}>Try manual input.</span></span>
      </div>
    </>
  )
}